<template>
    <div v-if="isOpenDelete" id="popup-modal" tabindex="-1" class="fixed inset-0 z-index-custom flex items-center justify-center">
        <!-- Background overlay -->
        <div @click="$emit('close')" class="absolute inset-0 bg-black opacity-70"></div>
        <!-- Modal content -->
        <div class="relative p-4 w-full max-w-md max-h-full">
            <div class="relative bg-white p-3 rounded-lg shadow-sm dark:bg-gray-700">
                <button @click="$emit('close')" type="button"
                    class="absolute top-3 right-2 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white">
                    <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                        viewBox="0 0 14 14">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                    </svg>
                    <span class="sr-only">Close modal</span>
                </button>
                <svg class="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200" aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                </svg>
                <h3 class="mb-3 text-lg text-center font-normal text-gray-500 dark:text-gray-400">Are you sure you want to delete
                    this Item?</h3>

                <div class="flex items-center justify-center md:p-2 text-center">
                    <button @click="$emit('confirm')" type="button"
                        class="text-white mr-1 bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-3 py-2.5 text-center">
                        Yes, I'm sure
                    </button>
                    <button @click="$emit('close')" type="button"
                        class="py-2.5 px-3 ms-3 ml-1 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
                        No, cancel
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        isOpenDelete: {
            type: Boolean,
            required: true
        },
    },
};
</script>
<style>
.z-index-custom {
    z-index: 1100;
}
</style>